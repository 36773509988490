import React from 'react';
import './styles.scss';
import Navbar from '../../../components/trivia/NavbarOld';
import Testimonials from '../../../components/trivia/TestimonialsOld';
import Client from '../../../components/springworks/OurClients';
import Feature from '../../../components/trivia/Feature';
import Footer from '../../../components/trivia/Footer';
import { Helmet } from 'react-helmet';
import WorkSpace from '../../../components/trivia/WorkSpace';
import { getUtmParams } from '../../../util/getUtmFromUrl';

const slackAddUrl = process.env.GATSBY_TRIVIA_SLACK_ADD_URL;
class SLACK extends React.Component {
    state = {
        utm: '',
    };

    componentDidMount() {
        const utm = getUtmParams();
        this.setState({ utm });
    }

    render() {
        const { utm } = this.state;
        return (
            <>
                <Helmet>
                    <title>Fun Slack App with Instant Games for Remote Teams | Trivia</title>
                    <meta
                        name='description'
                        content='With Trivia, bring Social & Fun to your company and empower your remote team with fun quizzes, puzzles, polls & games right inside Slack.'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='Fun Slack App with Instant Games for Remote Teams | Trivia' />
                    <meta
                        property='og:description'
                        content='With Trivia, bring Social & Fun to your company and empower your remote team with fun quizzes, puzzles, polls & games right inside Slack.'
                    />
                    <meta property='og:url' content='https://www.springworks.in/trivia/slack/' />
                    <meta property='og:type' content='website' />
                    <meta property='og:image' content='https://assets-springworks.s3.amazonaws.com/TriviaSlack.jpg' />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaSlack.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta
                        property='twitter:title'
                        content='Fun Slack App with Instant Games for Remote Teams | Trivia'
                    />
                    <meta
                        property='twitter:description'
                        content='With Trivia, bring Social & Fun to your company and empower your remote team with fun quizzes, puzzles, polls & games right inside Slack.'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaSlack.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/trivia/slack/' />
                </Helmet>
                <div className='pageWrap'>
                    <Navbar />
                    <div className='slackHeader'>
                        <h1>Build stronger and happier teams inside Slack</h1>
                        <p>
                            Right from instant quizzes to simple polls, psych games - Trivia’s suite of games bring your
                            remote teams together, without leaving Slack.
                        </p>
                        <button
                            id='TRIVIA-SLACK-INSTALL'
                            onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                        >
                            <img
                                src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                alt={'slack'}
                            />
                            Add Trivia to Slack
                        </button>
                    </div>

                    <div className='SlackTestimonials'>
                        <h5>Thousands of organizations trust Trivia for stronger team bonding</h5>
                        <Testimonials />
                    </div>

                    <div className='SlackClient'>
                        <h6>Trusted by the world’s fastest growing companies including</h6>
                        <Client />
                    </div>

                    <div className='SlackFeature'>
                        <Feature />
                    </div>

                    <div className='gChatWrapper'>
                        <div className='sw-container'>
                            <div className='gChatAddTrivia'>
                                <div className='gChatAddTriviaHeader'>
                                    <h5>3 Simple Steps to Pump-up Team Morale and Enhance Employee Bonding</h5>
                                </div>

                                <div className='gChatSteps'>
                                    <div className='gChatStepsInnerContent gMobileHeader'>
                                        <h5>Add Trivia to Slack</h5>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/slack/social-fun.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/slack/social-fun.png'}
                                            alt={'social fun'}
                                        />
                                    </div>
                                    <div className='gChatStepsInner'>
                                        <img
                                            className='deskViewImg'
                                            src={'https://sw-assets.gumlet.io/trivia/slack/social-fun.png'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/slack/social-fun.png'}
                                            alt={'social fun'}
                                        />
                                    </div>
                                    <div className='gChatStepsInner gChatStepsPad'>
                                        <div className='gChatStepsInnerContent'>
                                            <h5>Add Trivia to Slack</h5>
                                            <p>Search for the Trivia app and install it on Slack for free.</p>
                                            <button
                                                id='TRIVIA-SLACK-INSTALL'
                                                onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                                            >
                                                <img
                                                    src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                                    data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                                    alt={'slack'}
                                                />
                                                Add Trivia
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='gChatSteps'>
                                    <div className='gChatStepsInnerContent gMobileHeader'>
                                        <h5>Play Trivia Games</h5>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/slack/play-games.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/slack/play-games.png'}
                                            alt={'play games'}
                                        />
                                    </div>
                                    <div className='gChatStepsInner gChatStepsPad'>
                                        <div className='gChatStepsInnerContent'>
                                            <h5>Play Trivia Games</h5>
                                            <p>
                                                Get started with Trivia's suite of games, spread smiles, and bond better
                                                with your teammates.
                                            </p>
                                            <button
                                                onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                                                id='TRIVIA-SLACK-INSTALL'
                                            >
                                                <img
                                                    src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                                    data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                                    alt={'slack'}
                                                />
                                                Add Trivia
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gChatStepsInner'>
                                        <img
                                            className='deskViewImg'
                                            src={'https://sw-assets.gumlet.io/trivia/slack/play-games.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/slack/play-games.png'}
                                            alt={'play games'}
                                        />
                                    </div>
                                </div>

                                <div className='gChatSteps'>
                                    <div className='gChatStepsInnerContent gMobileHeader'>
                                        <h5>Bring Your Teams Together</h5>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/slack/your-teams.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/slack/your-teams.png'}
                                            alt={'your teams'}
                                        />
                                    </div>
                                    <div className='gChatStepsInner'>
                                        <img
                                            className='deskViewImg'
                                            src={'https://sw-assets.gumlet.io/trivia/slack/your-teams.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/slack/your-teams.png'}
                                            alt={'your teams'}
                                        />
                                    </div>
                                    <div className='gChatStepsInner gChatStepsPad'>
                                        <div className='gChatStepsInnerContent'>
                                            <h5>Bring Your Teams Together</h5>
                                            <p>
                                                With thousands of Trivia Quizzes, (Un)popular opinions, Game of Gotcha
                                                bring Social & Fun back to your workspace even while working remotely.
                                            </p>
                                            <button
                                                onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                                                id='TRIVIA-SLACK-INSTALL'
                                            >
                                                <img
                                                    src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                                    data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                                    alt={'slack'}
                                                />
                                                Add Trivia
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <WorkSpace />
                    <Footer />
                </div>
            </>
        );
    }
}
export default SLACK;

import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Accordion, Button, Card } from 'react-bootstrap';
import './styles.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const videos = {
    SLACK: {
        custom: (
            <img
                src={'https://sw-assets.gumlet.io/trivia/slack/custom.png'}
                data-src={'https://sw-assets.gumlet.io/trivia/slack/custom.png'}
                alt={'custom quiz'}
            />
        ),
        upo: (
            <img
                data-src={'https://sw-assets.gumlet.io/trivia/slack/unpopular-opinion.png'}
                src={'https://sw-assets.gumlet.io/trivia/slack/unpopular-opinion.png'}
                alt={'unpopular opinion'}
            />
        ),
        gotcha: (
            <img
                data-src={'https://sw-assets.gumlet.io/trivia/slack/Gotcha.png'}
                src={'https://sw-assets.gumlet.io/trivia/slack/Gotcha.png'}
                alt={'gotcha'}
            />
        ),
        instant: (
            <img
                data-src={'https://sw-assets.gumlet.io/trivia/slack/Quizzes.png'}
                src={'https://sw-assets.gumlet.io/trivia/slack/Quizzes.png'}
                alt={'quizzes'}
            />
        ),
    },
    MS_TEAMS: {
        custom: (
            <img
                src={'https://sw-assets.gumlet.io/trivia/teams/custom_msteam.png'}
                data-src={'https://sw-assets.gumlet.io/trivia/teams/custom_msteam.png'}
                alt={'custom quizzes'}
            />
        ),
        upo: (
            <img
                src={'https://sw-assets.gumlet.io/trivia/teams/unpopular-opinion_msteam.png'}
                data-src={'https://sw-assets.gumlet.io/trivia/teams/unpopular-opinion_msteam.png'}
                alt={'unpopular opinion'}
            />
        ),
        gotcha: (
            <img
                src={'https://sw-assets.gumlet.io/trivia/teams/Gotcha_msteam.png'}
                data-src={'https://sw-assets.gumlet.io/trivia/teams/Gotcha_msteam.png'}
                alt={'gotcha'}
            />
        ),
        instant: (
            <img
                src={'https://sw-assets.gumlet.io/trivia/teams/Quizzes_msteam.png'}
                data-src={'https://sw-assets.gumlet.io/trivia/teams/Quizzes_msteam.png'}
                alt={'instant quizzes'}
            />
        ),
    },
};

class Feature extends React.Component {
    state = {
        featureOpen: -1,
        showVideo: false,
        feature: 'instant',
    };

    componentDidMount() {
        if (window.innerWidth <= 1200) {
            this.setState({ showVideo: true });
        }
    }

    renderVideo() {
        const { showVideo, feature } = this.state;
        const { source } = this.props;
        if (showVideo) {
            return <div />;
        }
        return <div className='qucikOpinionSectionImage quickImage'>{videos[source][feature]}</div>;
    }

    render() {
        const { source } = this.props;

        return (
            <div className='featureCtr'>
                <div className='heading'>
                    {'Quick, easy to deploy, and engaging quizzes now on Slack and Microsoft Teams!'}
                </div>
                <div className='qucikOpinionSectionWrapper'>
                    <div className='qucikOpinionSection OpinionSection'>
                        <div className='quickOpinionSectionText'>
                            <div className='quickOpinionSectionAccordion'>
                                <Accordion>
                                    <Card>
                                        <Card.Header
                                            className={this.state.featureOpen === 0 ? 'accordinOpen' : 'accordingClose'}
                                        >
                                            <Accordion.Toggle
                                                as={Button}
                                                variant='link'
                                                eventKey='0'
                                                onClick={() =>
                                                    this.setState({
                                                        featureOpen: this.state.featureOpen === 0 ? -1 : 0,
                                                        feature: 'instant',
                                                    })
                                                }
                                            >
                                                <span>Instant Quizzes </span>
                                                <span>
                                                    {this.state.featureOpen !== 0 ? (
                                                        <img
                                                            className='AccordionAdd'
                                                            src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/plus-icon.svg'
                                                            }
                                                            data-src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/plus-icon.svg'
                                                            }
                                                            alt='Plus Icon'
                                                        />
                                                    ) : (
                                                        <img
                                                            className='AccordionRemove'
                                                            src={'https://sw-assets.gumlet.io/trivia/misc/remove.svg'}
                                                            data-src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/remove.svg'
                                                            }
                                                            alt='Minus Icon'
                                                        />
                                                    )}
                                                </span>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey='0'>
                                            <Card.Body>
                                                {this.state.showVideo && videos[source]['instant']}
                                                <p>28,000+ questions across 30 categories in an MCQ quiz format</p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <Card.Header
                                            className={this.state.featureOpen === 1 ? 'accordinOpen' : 'accordingClose'}
                                        >
                                            <Accordion.Toggle
                                                as={Button}
                                                variant='link'
                                                eventKey='1'
                                                onClick={() =>
                                                    this.setState({
                                                        featureOpen: this.state.featureOpen === 1 ? -1 : 1,
                                                        feature: 'upo',
                                                    })
                                                }
                                            >
                                                <span>(Un)popular opinions</span>
                                                <span>
                                                    {this.state.featureOpen !== 1 ? (
                                                        <img
                                                            className='AccordionAdd'
                                                            src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/plus-icon.svg'
                                                            }
                                                            data-src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/plus-icon.svg'
                                                            }
                                                            alt='Plus Icon'
                                                        />
                                                    ) : (
                                                        <img
                                                            className='AccordionRemove'
                                                            src={'https://sw-assets.gumlet.io/trivia/misc/remove.svg'}
                                                            data-src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/remove.svg'
                                                            }
                                                            alt='Minus Icon'
                                                        />
                                                    )}
                                                </span>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey='1'>
                                            <Card.Body>
                                                {this.state.showVideo && videos[source]['upo']}
                                                <p>
                                                    Vote for tons of opinions with your teammates and stir up some
                                                    controversy
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header
                                            className={this.state.featureOpen === 2 ? 'accordinOpen' : 'accordingClose'}
                                        >
                                            <Accordion.Toggle
                                                as={Button}
                                                variant='link'
                                                eventKey='2'
                                                onClick={() =>
                                                    this.setState({
                                                        featureOpen: this.state.featureOpen === 2 ? -1 : 2,
                                                        feature: 'gotcha',
                                                    })
                                                }
                                            >
                                                <span>Game of Gotcha</span>
                                                <span>
                                                    {this.state.featureOpen !== 2 ? (
                                                        <img
                                                            className='AccordionAdd'
                                                            src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/plus-icon.svg'
                                                            }
                                                            data-src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/plus-icon.svg'
                                                            }
                                                            alt='Plus Icon'
                                                        />
                                                    ) : (
                                                        <img
                                                            className='AccordionRemove'
                                                            src={'https://sw-assets.gumlet.io/trivia/misc/remove.svg'}
                                                            data-src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/remove.svg'
                                                            }
                                                            alt='Minus Icon'
                                                        />
                                                    )}
                                                </span>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey='2'>
                                            <Card.Body>
                                                {this.state.showVideo && videos[source]['gotcha']}
                                                <p>
                                                    An MCQ quiz format with a twist where it not only pays to know the
                                                    right answer, but fooling others with your wrong answer can fetch
                                                    you points too
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <Card.Header
                                            className={this.state.featureOpen === 4 ? 'accordinOpen' : 'accordingClose'}
                                        >
                                            <Accordion.Toggle
                                                as={Button}
                                                variant='link'
                                                eventKey='4'
                                                onClick={() =>
                                                    this.setState({
                                                        featureOpen: this.state.featureOpen === 4 ? -1 : 4,
                                                        feature: 'custom',
                                                    })
                                                }
                                            >
                                                <span>Custom quizzes</span>
                                                <span>
                                                    {this.state.featureOpen !== 4 ? (
                                                        <img
                                                            className='AccordionAdd'
                                                            src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/plus-icon.svg'
                                                            }
                                                            data-src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/plus-icon.svg'
                                                            }
                                                            alt='Plus Icon'
                                                        />
                                                    ) : (
                                                        <img
                                                            className='AccordionRemove'
                                                            src={'https://sw-assets.gumlet.io/trivia/misc/remove.svg'}
                                                            data-src={
                                                                'https://sw-assets.gumlet.io/trivia/misc/remove.svg'
                                                            }
                                                            alt='Minus Icon'
                                                        />
                                                    )}
                                                </span>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey='4'>
                                            <Card.Body>
                                                {this.state.showVideo && videos[source]['custom']}
                                                <p>
                                                    Quizzes that have personalized questions, answers and categories of
                                                    your choice
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                        {this.renderVideo()}
                    </div>
                </div>
                <div className='feature-wrap'>
                    <div className='feature-header'>
                        <h3>Why use Trivia?</h3>
                    </div>
                    <div className='feature'>
                        <div className='cardCtr'>
                            <div className='cardLogo'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/trivia/misc/seamless-integration-with-trivia.svg?blur=30'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/trivia/misc/seamless-integration-with-trivia.svg'
                                    }
                                    alt='Seamless Integration with Slack'
                                />
                            </div>
                            <div className='cardTitle'>{'Seamless integration'}</div>
                            <div className='cardText'>
                                Start a Trivia game to play with your team anytime, anywhere right on top of Slack and
                                Microsoft Teams.
                            </div>
                        </div>
                        <div className='cardCtr'>
                            <div className='cardLogo'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/trivia/misc/multiple-game-types-with-trivia.svg?blur=30'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/trivia/misc/multiple-game-types-with-trivia.svg'
                                    }
                                    alt='Multiple Game Types with Trivia'
                                />
                            </div>
                            <div className='cardTitle'>{'Multiple game types'}</div>
                            <div className='cardText'>
                                Every day is different from the other, and that’s why we want to give you a variety of
                                game types.
                            </div>
                        </div>
                        <div className='cardCtr'>
                            <div className='cardLogo'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/trivia/misc/trivia-leaderboard-feature.svg?blur=30'
                                    }
                                    data-src={'https://sw-assets.gumlet.io/trivia/misc/trivia-leaderboard-feature.svg'}
                                    alt='Trivia Leaderboard Feature'
                                />
                            </div>
                            <div className='cardTitle'>{'Leaderboards'}</div>
                            <div className='cardText'>
                                Get summarised results at the end of every Trivia quiz and find your very own Quizzard!
                            </div>
                        </div>
                    </div>
                </div>
                <div className='slider'>
                    <Carousel
                        showArrows={false}
                        showStatus={false}
                        swipeable={true}
                        autoPlay={true}
                        infiniteLoop={true}
                        showThumbs={false}
                    >
                        <div className='sliderFeature'>
                            <div className='featureImage'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/trivia/misc/seamless-integration-with-trivia.svg?blur=30'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/trivia/misc/seamless-integration-with-trivia.svg'
                                    }
                                    alt='Seamless Integration with Slack'
                                />
                            </div>
                            <div className='featureHeading'>{'Seamless integration'}</div>
                            <div className='featureSubHeading'>
                                Start a Trivia game to play with your team anytime, anywhere right on top of Slack and
                                Microsoft Teams.
                            </div>
                        </div>
                        <div className='sliderFeature'>
                            <div className='featureImage'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/trivia/misc/multiple-game-types-with-trivia.svg?blur=30'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/trivia/misc/multiple-game-types-with-trivia.svg'
                                    }
                                    alt='Multiple Game Types with Trivia'
                                />
                            </div>
                            <div className='featureHeading'>{'Multiple game speeds'}</div>
                            <div className='featureSubHeading'>
                                Every day is different from the other, and that’s why we want to give you a variety of
                                game types.
                            </div>
                        </div>
                        <div className='sliderFeature'>
                            <div className='featureImage'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/trivia/misc/trivia-leaderboard-feature.svg?blur=30'
                                    }
                                    data-src={'https://sw-assets.gumlet.io/trivia/misc/trivia-leaderboard-feature.svg'}
                                    alt='Trivia Leaderboard Feature'
                                />
                            </div>
                            <div className='featureHeading'>{'Leaderboards'}</div>
                            <div className='featureSubHeading'>
                                Get summarised results at the end of every Trivia quiz and find your very own Quizzard!
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        );
    }
}

Feature.defaultProps = {
    source: 'SLACK',
};

export default Feature;
